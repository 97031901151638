import generateCDN from '@/utils/generate-cdn';
import { pageRoutes } from '@/utils/routes/urls';
import videoIcon from '@/public/assets/images/carousel/videoIcon.svg';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { forwardRef, useMemo } from 'react';
import { Merchandise } from '@/redux/merchandise/slice';
import classNames from 'classnames';
import { useAddFavoriteMutation, useRemoveFavoriteMutation } from '@/redux/user/api';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { ErrorToastContainer } from '@/components/toasts';
import useCurrentUser from '@/hooks/useCurrentUser.hook';
import { MSG_DISPLAY } from '@/utils/msgDisplay';
import './millionaire-card.scss';

export interface MillionaireCardProps {
    merchandise: Merchandise;
    isInactive?: boolean;
    genreName?: string;
    isFavorite: boolean;
    setFavorite?: React.Dispatch<React.SetStateAction<Record<string | number, boolean>>>;
    setFavoriteCallback?: (id: Merchandise['id'], addOrRemove: 'add' | 'remove') => void;
    setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
    isNumberShown?: boolean;
    index?: number;
    isNew?: boolean;
    isTopTen?: boolean;
}

const MillionaireCard = forwardRef<HTMLAnchorElement, MillionaireCardProps>(function MillionaireCard(
    {
        merchandise,
        isInactive,
        genreName,
        isFavorite = false,
        setOpenModal,
        setFavorite,
        setFavoriteCallback,
        isNumberShown,
        index = 0,
        isNew,
        isTopTen,
    },
    observerRef
) {
    const router = useRouter();
    const [addToFavorite, { isLoading: addOngoing }] = useAddFavoriteMutation();
    const [removeFavorite, { isLoading: removeOngoing }] = useRemoveFavoriteMutation();
    const authToken = useSelector<RootState, string>((state) => state.auth.loggedInToken);
    const { isViewer, isLoggedIn } = useCurrentUser();
    const merchandisePrice = useMemo(() => {
        if (merchandise.priceVisibility === 1) return `¥${merchandise.price}〜`;
        else if (merchandise.priceVisibility === 2) return `¥${merchandise.price}`;
        return 'お問い合わせください';
    }, [merchandise.priceVisibility, merchandise.price]);

    function handleInquiryRedirect(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (authToken) {
            router.push({ pathname: pageRoutes.contact, query: { merchandise: merchandise.id } });
        } else {
            router.push(
                {
                    pathname: router.pathname,
                    query: { merchandise: merchandise.id, next: pageRoutes.contact },
                },
                undefined,
                { scroll: false, shallow: true }
            );
            if (setOpenModal) setOpenModal((prev) => !prev);
        }
    }

    function handleFavorite() {
        if (isFavorite) {
            removeFavorite(merchandise.id)
                .unwrap()
                .then((removed_id) => {
                    if (setFavorite) setFavorite((prev) => ({ ...prev, [Number(removed_id)]: false }));
                    if (setFavoriteCallback) setFavoriteCallback(removed_id, 'remove');
                })
                .catch((err) => ErrorToastContainer({ message: err.message || MSG_DISPLAY.sthWentWrong }));
        } else {
            addToFavorite(merchandise.id)
                .unwrap()
                .then((inserted_id) => {
                    if (setFavorite) setFavorite((prev) => ({ ...prev, [Number(inserted_id)]: true }));
                    if (setFavoriteCallback) setFavoriteCallback(inserted_id, 'add');
                })
                .catch((err) => ErrorToastContainer({ message: err.message || MSG_DISPLAY.sthWentWrong }));
        }
    }

    return (
        <React.Fragment>
            <Link
                ref={observerRef}
                href={{ pathname: pageRoutes.merchandiseDetails, query: { id: merchandise.id } }}
                className={`mt-card__link ${isInactive ? 'mt-card__link--isInactive' : ''}`}
            >
                <div className="mt-card__frame">
                    <div className="mt-card__scaler">
                        <div className="mt-card__data-container">
                            <div className="mt-card__data-container--wrapper">
                                {isNumberShown && (
                                    <div className="mt-card__number-div">
                                        <p>{index + 1}</p>
                                    </div>
                                )}
                                {(isNew || isTopTen) && (
                                    <div className="mt-card__tag-div">
                                        {isNew && (
                                            <div className="mt-card__tag-div__item mt-card__tag-div__new">NEW</div>
                                        )}
                                        {isTopTen && !isNumberShown && (
                                            <div className="mt-card__tag-div__item mt-card__tag-div__top-10">
                                                <p>TOP</p>
                                                <p className="mt-card__tag-div__top-10--number">10</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <img
                                    className="mt-card__image mt-card__image--vertical-mb"
                                    src={generateCDN(
                                        merchandise.media?.basePath || '',
                                        (merchandise.media?.verticalThumbPath as string) || ''
                                    )}
                                    alt="vertical thumbnail"
                                    loading="lazy"
                                />
                                <img
                                    className="mt-card__image mt-card__image--vertical-pc"
                                    src={generateCDN(
                                        merchandise.media?.basePath || '',
                                        (merchandise.media?.horizontalThumbPath as string) || ''
                                    )}
                                    alt="vertical thumbnail"
                                    loading="lazy"
                                />

                                <img
                                    className="mt-card__image mt-card__image--horizontal"
                                    src={generateCDN(
                                        merchandise.media?.basePath || '',
                                        (merchandise.media?.horizontalThumbPath as string) || ''
                                    )}
                                    alt="horizontal thumbnail"
                                    loading="lazy"
                                />

                                <Image className="mt-card__video-icon" src={videoIcon} alt="play video" />
                                <div
                                    className="mt-card__info-area"
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <div className="mt-card__info-area__logo-container">
                                        <div className="mt-card__info-area__logo">
                                            {merchandise.media?.logoPath && (
                                                <>
                                                    <Image
                                                        className="mt-card__info-area__logo-product-company"
                                                        src={generateCDN(
                                                            merchandise.media?.basePath || '',
                                                            merchandise.media.logoPath as string
                                                        )}
                                                        unoptimized
                                                        alt="product logo"
                                                        width={57.7}
                                                        height={26.7}
                                                        loading="lazy"
                                                    />
                                                    <div className="mt-card__info-area__vertical-line"></div>
                                                </>
                                            )}
                                            <Image
                                                className="mt-card__info-area__logo-product-company"
                                                src={generateCDN(
                                                    merchandise?.enterprise?.media.basePath || '',
                                                    (merchandise?.enterprise?.media.logoPath as string) || ''
                                                )}
                                                alt="product logo"
                                                unoptimized
                                                width={82.9}
                                                height={26.7}
                                                loading="lazy"
                                            />
                                        </div>
                                        <Link
                                            href={{
                                                pathname: pageRoutes.merchandiseDetails,
                                                query: { id: merchandise.id },
                                            }}
                                            className="mt-card__info-area__button-view-info"
                                        >
                                            <span>VIEW MORE</span>
                                            <Image
                                                className="mt-card__info-area__button-view-info-image"
                                                src="/assets/icons/arrow-golden.svg"
                                                alt="golden arrow icon"
                                                height={13}
                                                width={73.5}
                                            />
                                        </Link>
                                    </div>
                                    <div className="mt-card__info-area__title">
                                        {merchandise.enterprise?.user.corporateName}
                                    </div>
                                    <div className="mt-card__info-area__representative">{merchandise.title}</div>
                                    <div className="mt-card__info-area__button">
                                        <div className="mt-card__info-area__button-price">
                                            PRICE
                                            <br />
                                            <span className="mt-card__info-area__card-price">{merchandisePrice}</span>
                                        </div>
                                        <button
                                            type="button"
                                            className={classNames(
                                                'mt-card__info-area__button-inquiry',
                                                isLoggedIn && !isViewer && 'mt-card__info-area__button-inquiry--hidden'
                                            )}
                                            onClick={handleInquiryRedirect}
                                        >
                                            お問い合わせ
                                        </button>
                                    </div>
                                    <div className="mt-card__info-area__favorites-overseas">
                                        <button
                                            type="button"
                                            className={classNames(
                                                'mt-card__info-area__favorites',
                                                (addOngoing || removeOngoing) &&
                                                    'mt-card__info-area__favorites--loading',
                                                isLoggedIn && !isViewer && 'mt-card__info-area__favorites--hidden'
                                            )}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                authToken
                                                    ? handleFavorite()
                                                    : setOpenModal && setOpenModal((prev) => !prev);
                                            }}
                                            disabled={addOngoing || removeOngoing}
                                        >
                                            {addOngoing || removeOngoing ? (
                                                ''
                                            ) : (
                                                <>
                                                    {!isViewer || !isFavorite ? (
                                                        <Image
                                                            src={'/assets/icons/fav_off.svg'}
                                                            alt="addFavorite"
                                                            width={10}
                                                            height={10}
                                                            loading="eager"
                                                            priority={true}
                                                        />
                                                    ) : (
                                                        <Image
                                                            src={'/assets/icons/fav_on.svg'}
                                                            alt="removeFavorite"
                                                            width={11.4}
                                                            height={9.8}
                                                            loading="eager"
                                                            priority={true}
                                                        />
                                                    )}
                                                    <span>{'お気に入り'}</span>
                                                </>
                                            )}
                                        </button>
                                        {genreName && (
                                            <button className="mt-card__info-area__overseas">{genreName}</button>
                                        )}
                                    </div>

                                    <div className="mt-card__info-area__business-content">
                                        {merchandise.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </React.Fragment>
    );
});

export default MillionaireCard;

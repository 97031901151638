import { API_ROUTES } from '@/utils/routes/apiRoutes';
import { apiSlice } from '../api/apiSlice';
import { Merchandise } from '../merchandise/slice';

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addFavorite: builder.mutation<Merchandise['id'], Merchandise['id']>({
            query: (id) => {
                return {
                    url: API_ROUTES.addFavorite,
                    method: 'POST',
                    body: { merchandiseId: id },
                };
            },
            transformResponse: (response) => {
                return response.result.id;
            },
        }),
        removeFavorite: builder.mutation<Merchandise['id'], Merchandise['id']>({
            query: (id) => {
                return {
                    url: API_ROUTES.removeFavorite,
                    method: 'POST',
                    body: { merchandiseId: id },
                };
            },
            transformResponse: (response) => {
                return response.result.id;
            },
        }),
    }),
});

export const { useAddFavoriteMutation, useRemoveFavoriteMutation } = userApiSlice;

import { AuthState, initialUser } from '@/redux/auth/authSlice';
import { RootState } from '@/redux/rootReducer';
import { Role, User } from '@/redux/user/slice';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useCurrentUser() {
    const [user, setUser] = useState<User>(initialUser());
    const [token, setToken] = useState<AuthState['loggedInToken']>();

    const auth = useSelector<RootState, AuthState>((state) => state.auth);
    const isLoggedIn = useMemo(() => (auth.loggedInToken ? true : false), [auth.loggedInToken]);
    const isViewer = useMemo(
        () => auth.loggedInUser.roles?.some((role) => role.title === Role.VIEWING_USER),
        [auth.loggedInUser]
    );
    const isEnterprise = useMemo(
        () =>
            auth.loggedInUser.roles?.some(
                (role) =>
                    role.title === Role.ENTERPRISE ||
                    role.title === Role.ENTERPRISE_MANAGER ||
                    role.title === Role.ENTERPRISE_SUPERVISOR
            ),
        [auth.loggedInUser]
    );
    const isEnterpriseOwner = useMemo(
        () => auth.loggedInUser.roles?.some((role) => role.title === Role.ENTERPRISE),
        [auth.loggedInUser]
    );
    const isEnterpriseNonOwner = useMemo(
        () =>
            auth.loggedInUser.roles?.some(
                (role) => role.title === Role.ENTERPRISE_MANAGER || role.title === Role.ENTERPRISE_SUPERVISOR
            ),
        [auth.loggedInUser]
    );

    useEffect(() => {
        setUser(auth.loggedInUser);
        setToken(auth.loggedInToken);
    }, [auth]);

    return {
        id: user.id,
        name: user.name,
        email: user.email,
        token,
        isLoggedIn,
        isViewer,
        isEnterprise,
        isEnterpriseOwner,
        isEnterpriseNonOwner,
    };
}
